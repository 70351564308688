import React from "react";
import { AntDFormState, AntDFormStateWithoutSplit } from "@types";
import { Button, Collapse, Result, Row, Typography } from "antd";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import { GlAccountByType } from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import {
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { TransferModalFormV2 } from "./TransactionForms/TransferModalFormV2";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { PaymentModalFormV2 } from "./TransactionForms/PaymentModalFormV2";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { level2Spacing } from "utils/style-utils";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { ModalFooter } from "Components/Form/ModalFooter";
import { DraggableModal } from "Components/DraggableModal";
import { useQuery } from "react-query";
import { getVenueGlAccountsById } from "../../../ApiV2/Helpers/getVenueGlAccountsById";
import { useVenueSelection } from "../../../Context/VenueSelectionContextConstants";
import useTransactionPayment from "hooks/useTransactionPayment";
import useTransactionModal from "hooks/useTransactionModal";
import useTransactionTransfer from "../../../hooks/useTransactionTransfer";

const { Panel } = Collapse;

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

interface Props {
    onModalClose?: () => void;
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    toLocations: ExtendedLocationItemWithChildren[];
    fromLocations: ExtendedLocationItemWithChildren[];
    allLocations?: ExtendedLocationItemWithChildren[];
    currentLocationID?: string;
    TransferTransactionsReadOnly: TransferTransactionInit[];
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    hideTransferTo?: boolean;
    disabled?: boolean;
    TransactionsForFloat?: boolean;
    forceIncludeBank?: boolean;
    PaymentTransactionData: PaymentTransactionInit[];
    onPaymentTransactionSubmission: (data: AntDFormState[]) => void;
    onPaymentTransactionRemove: (data: AntDFormState[]) => void;
    isPaymentNegative?: boolean;
    currentExpectedValue?: number;
}

const TransactionsFormTransfersOfficeComponent: React.FC<Props> = ({
    onModalClose,
    onTransferTransactionSubmission,
    TransferTransactions,
    toLocations,
    fromLocations,
    currentLocationID,
    TransferTransactionsReadOnly,
    accountsData,
    classesData,
    hideTransferTo,
    disabled,
    PaymentTransactionData,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    isPaymentNegative,
}) => {
    const {
        closeModal,
        formNamesNeedToSave,
        isVisible,
        setFormNeedToSave,
        setIsVisible,
    } = useTransactionModal({ onModalClose });

    const {
        paymentFields,
        initialPaymentTransactionsDataWithAppendedFormID,
        tenderAccounts,
    } = useTransactionPayment({
        isPaymentNegative,
        accountsData,
        classesData,
        PaymentTransactionData,
    });

    const {
        transferFields,
        transferFieldsReadOnly,
        initialTransfersTransactionsDataWithAppendedFormID,
        initialTransfersReadOnlyTransactionsDataWithAppendedFormID,
    } = useTransactionTransfer({
        currentLocationID,
        toLocations: toLocations,
        fromLocations: fromLocations,
        TransferTransactions,
        TransferTransactionsReadOnly,
        includeDefaultReadonlyValue: true,
    });

    const { venueId } = useVenueSelection();
    const { data: glAccounts } = useQuery(["glAccounts", venueId], () =>
        getVenueGlAccountsById(venueId)
    );

    // @ts-ignore
    const glAccountGroupsByType = (glAccounts ? glAccounts : []).reduce<
        GlAccountByType[]
        // @ts-ignore
    >((acc, currentGlAccount, index) => {
        const matchGroup = acc.find(
            // @ts-ignore
            (acc) => acc.type === currentGlAccount.gl_account.type
        );
        if (index === 0 || !matchGroup) {
            acc.push({
                type: currentGlAccount.gl_account.type,
                glAccounts: [currentGlAccount.gl_account],
            });
        } else {
            matchGroup.glAccounts.push(currentGlAccount.gl_account);
        }
        return acc;
    }, []);

    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                <Panel
                    header={<StyledPanelHeader>Transfers From</StyledPanelHeader>}
                    key="1"
                >
                    <TransferModalFormV2
                        currentLocationID={currentLocationID}
                        fields={transferFields}
                        initialDataForForms={
                            initialTransfersTransactionsDataWithAppendedFormID
                        }
                        onChange={onTransferTransactionSubmission}
                        disabled={disabled}
                        setFormNeedToSave={setFormNeedToSave}
                    />
                </Panel>
                {hideTransferTo !== true && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="2"
                    >
                        <TransferModalFormV2
                            fields={transferFieldsReadOnly}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                        />
                    </Panel>
                )}

                <Panel
                    header={<StyledPanelHeader>Payments</StyledPanelHeader>}
                    key="3"
                >
                    <Typography
                        style={{ color: "#626E84", marginBottom: level2Spacing }}
                    >
                        Payments will include petty cash and other expenses
                    </Typography>
                    <PaymentModalFormV2
                        fields={paymentFields}
                        onModalClose={() => null}
                        initialDataForForms={
                            initialPaymentTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onSubmit={onPaymentTransactionSubmission}
                        onRemove={onPaymentTransactionRemove}
                        glAccountsByType={glAccountGroupsByType!}
                        setFormNeedToSave={setFormNeedToSave}
                        disabled={disabled}
                    />
                </Panel>
            </Collapse>
            <ModalFooter
                onClose={closeModal}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
            <DraggableModal
                open={isVisible}
                onCancel={() => setIsVisible(false)}
                onOk={() => setIsVisible(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px" }}
                zIndex={100000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                width={"80%"}
            >
                <Result
                    status="warning"
                    title={`${formNamesNeedToSave} forms not save, please click on save icon before you leave`}
                    extra={
                        <Row style={{ justifyContent: "center" }}>
                            <Button onClick={() => setIsVisible(false)}>OK</Button>
                        </Row>
                    }
                />
            </DraggableModal>
        </StyledFormContainer>
    );
};

export const TransactionsFormTransfersOffice = React.memo(
    TransactionsFormTransfersOfficeComponent
);
