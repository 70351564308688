import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Space, Typography, Statistic, Spin, Tooltip } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CountUp from "react-countup";
import { useQuery } from "react-query";
import { getCashupReport } from "../../../../ApiV2/Helpers/getCashupReport";
import { FormatConfig, Formatter, valueType } from "antd/es/statistic/utils";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import {
    KeyNumberContainer,
    LocationContainer,
    TotalTableContainer,
    CustomTotalDataTable,
    LoadingContainer,
    NoShiftDataContainer,
    TableTitle,
    DownloadLoadingContainer,
} from "../CashupReportStyled";
import BankableReportTable from "./BankableReportTable";
import { BankableOverviewReportObject, ReportItem } from "@interfaces/index";
import { CashupReportType, BankableOverviewType } from "@Constants/enum/Cashup";
import { apiUrlTypes } from "../../const";
import {
    bankableColumnGenerator,
    totalsForPayouts,
    downloadPdfReport,
    checkDateRange,
    downloadCsvReport,
    prepareBankableCsvData,
} from "../../utils";
import { contactSupport } from "utils/utilities";

const { Title } = Typography;

interface BankableReportProps {
    reportType: string;
    date: { start_date: string; end_date: string };
    venue: string;
    venueName: string;
    calendarStatus: boolean;
}

const CashupBankableReport: React.FC<BankableReportProps> = ({
    reportType,
    date,
    venue,
    venueName,
    calendarStatus,
}) => {
    const [download, setDownload] = useState(false as boolean);
    const [rangeCheck, setRangeCheck] = useState(false as boolean);

    useEffect(() => {
        if (checkDateRange(date, 14)) {
            setRangeCheck(true);
        }
    }, []);

    //get data from report api
    const getReportData = async (url: string, location: string) => {
        const reportUrlType = url;
        if (!calendarStatus) {
            const response = await getCashupReport(
                reportUrlType,
                venue,
                date.start_date,
                date.end_date,
                location
            );
            return response.json();
        }
    };

    const useFetchReportData = (reportType: string, location: string) => {
        const urlType = apiUrlTypes[reportType];
        return useQuery(
            [reportType, location],
            () => getReportData(urlType, location),
            {
                enabled: date.start_date.length > 0 && date.end_date.length > 0,
                refetchOnWindowFocus: false,
            }
        );
    };

    // POS Bankable API
    const {
        data: posData = [],
        isFetching: posApiStatus,
        error: posError,
        refetch: refetchPos,
    } = useFetchReportData(CashupReportType.BankableReport, "pos");

    // Gaming Bankable API
    const {
        data: gamingData = [],
        isFetching: gamingApiStatus,
        error: gamingError,
        refetch: refetchGaming,
    } = useFetchReportData(CashupReportType.BankableReport, "gaming");

    // Wagering Bankable API
    const {
        data: wageringData = [],
        isFetching: wageringApiStatus,
        error: wageringError,
        refetch: refetchWagering,
    } = useFetchReportData(CashupReportType.BankableReport, "wagering");

    // ATM Bankable API
    const {
        data: atmData = [],
        isFetching: atmApiStatus,
        error: atmError,
        refetch: refetchAtm,
    } = useFetchReportData(CashupReportType.BankableReport, "atm");

    // Office Bankable API
    const {
        data: officeData = [],
        isFetching: officeApiStatus,
        error: officeError,
        refetch: refetchOffice,
    } = useFetchReportData(CashupReportType.BankableReport, "office");

    // Bankable Overview API
    const {
        data: overviewData = {} as BankableOverviewReportObject,
        isFetching: overviewApiStatus,
        error: overviewError,
        refetch: refetchOverview,
    } = useFetchReportData(CashupReportType.BankableOverview, "");

    useEffect(() => {
        refetchPos();
        refetchGaming();
        refetchWagering();
        refetchAtm();
        refetchOffice();
        refetchOverview();
    }, [
        venue,
        refetchPos,
        refetchGaming,
        refetchWagering,
        refetchAtm,
        refetchOffice,
        refetchOverview,
    ]);

    // Combine all data
    const combinedData = useMemo(() => {
        return [
            ...posData,
            ...gamingData,
            ...wageringData,
            ...atmData,
            ...officeData,
        ];
    }, [posData, gamingData, wageringData, atmData, officeData]);

    // Combine fetching statuses
    const combinedStatus =
        posApiStatus ||
        gamingApiStatus ||
        wageringApiStatus ||
        atmApiStatus ||
        officeApiStatus ||
        overviewApiStatus;

    //prepare overall total columns
    const totalColumns = useMemo(
        () => bankableColumnGenerator(false, null, null),
        []
    );

    const totals: Record<string, number> = useMemo(
        () => (combinedData.length > 0 ? totalsForPayouts(combinedData) : {}),
        [combinedData]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...totals,
    };

    const formatter: Formatter = (value: valueType, formatConfig?: FormatConfig) => {
        if (typeof value === "string") {
            return value;
        }
        return <CountUp end={value} separator="," decimals={2} duration={1} />;
    };

    //open quantaco support modal
    const click = () => {
        contactSupport();
    };

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `${reportType}`;
        await downloadPdfReport(reportName, "bankable-report", venueName, date);
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const csvData = prepareBankableCsvData(combinedData, totals);
        const reportName = `${reportType}`;
        downloadCsvReport(reportName, venueName, date, csvData, overviewData);
        setDownload(false);
    };

    const renderDataComponent = (
        apiStatus: boolean,
        data: ReportItem[],
        title: string,
        error?: any
    ) => {
        return apiStatus ? (
            <Row gutter={[20, 2]}>
                <Col xs={24}>
                    <LoadingContainer>
                        <CentredSpinner size="large" />
                    </LoadingContainer>
                </Col>
            </Row>
        ) : (
            <>
                {error ? (
                    error.detail.includes(
                        `Invalid location type: ${title.toLowerCase()}`
                    ) ? null : (
                        <NoShiftDataContainer>
                            <TableTitle>
                                There was an error when trying to retrieve {title}{" "}
                                data. Please contact{" "}
                                <a onClick={click}>Quantaco Support</a> for
                                assistance.
                            </TableTitle>
                        </NoShiftDataContainer>
                    )
                ) : data[0]?.data.length > 0 ? (
                    <Row gutter={[20, 2]} key={`${data[0].location_type}`}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`${data[0].location_type}`}</TableTitle>
                                <BankableReportTable
                                    bankableData={data[0]}
                                    downloading={download}
                                />
                            </LocationContainer>
                        </Col>
                    </Row>
                ) : (
                    <NoShiftDataContainer>
                        <TableTitle>{`${title} bankable data is not available for the selected date range.`}</TableTitle>
                    </NoShiftDataContainer>
                )}
            </>
        );
    };

    if (date.start_date.length < 1 && date.end_date.length < 1) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    if (rangeCheck) {
        return (
            <Title level={5}>
                Cashup bankable reports are limited to a 14-day date range. Please
                adjust your date range selection.
            </Title>
        );
    }

    return (
        <>
            <div id="bankable-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${reportType}`}</Title>
                        </Col>
                        <Col>
                            {!combinedStatus ? (
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <Tooltip title="Download PDF">
                                        <FilePdfOutlined
                                            style={{
                                                fontSize: "28px",
                                                color: "#113469",
                                            }}
                                            onClick={onClick}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Download CSV">
                                        <FileTextOutlined
                                            style={{
                                                fontSize: "28px",
                                                color: "#113469",
                                            }}
                                            onClick={onCSVClick}
                                        />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        {BankableOverviewType &&
                            Object.keys(BankableOverviewType).map((item: string) => {
                                const enumKey =
                                    item as keyof typeof BankableOverviewType;
                                return (
                                    <Col md={8} lg={8} xs={24} key={item}>
                                        <KeyNumberContainer>
                                            {overviewApiStatus ? (
                                                <CentredSpinner size="large" />
                                            ) : (
                                                <>
                                                    <Statistic
                                                        valueStyle={{
                                                            fontSize: 28,
                                                            fontWeight:
                                                                "700 !important",
                                                            color: "#113469",
                                                        }}
                                                        value={
                                                            overviewData.key_numbers
                                                                ? overviewData
                                                                      .key_numbers[
                                                                      enumKey
                                                                  ]
                                                                : 0
                                                        }
                                                        precision={2}
                                                        prefix={"$"}
                                                        formatter={formatter}
                                                    />
                                                    <div
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: "SemiBold",
                                                        }}
                                                    >
                                                        {
                                                            BankableOverviewType[
                                                                enumKey
                                                            ]
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </KeyNumberContainer>
                                    </Col>
                                );
                            })}
                    </Row>
                    {combinedStatus ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : combinedData && combinedData.length > 0 ? (
                        <>
                            <Row gutter={[20, 2]}>
                                <Col xs={24}>
                                    <LocationContainer>
                                        <TableTitle>{`Total (${dayjs(
                                            date.start_date,
                                            "DD-MM-YYYY"
                                        ).format("DD/MM/YYYY")} - ${dayjs(
                                            date.end_date,
                                            "DD-MM-YYYY"
                                        ).format("DD/MM/YYYY")})`}</TableTitle>
                                        <TotalTableContainer>
                                            <CustomTotalDataTable
                                                rowKey="date"
                                                dataSource={[totalRow]}
                                                columns={totalColumns}
                                                size="small"
                                                scroll={{ x: true }}
                                                tableLayout="fixed"
                                                pagination={false}
                                            />
                                        </TotalTableContainer>
                                    </LocationContainer>
                                </Col>
                            </Row>
                        </>
                    ) : null}
                    {renderDataComponent(posApiStatus, posData, "POS", posError)}
                    {renderDataComponent(
                        gamingApiStatus,
                        gamingData,
                        "Gaming",
                        gamingError
                    )}
                    {renderDataComponent(
                        wageringApiStatus,
                        wageringData,
                        "Wagering",
                        wageringError
                    )}
                    {renderDataComponent(atmApiStatus, atmData, "ATM", atmError)}
                    {renderDataComponent(
                        officeApiStatus,
                        officeData,
                        "Office",
                        officeError
                    )}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupBankableReport;
